import React, { useState, useEffect, useRef } from "react"

import { Button, Link } from "gatsby-theme-material-ui"

import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import MenuIcon from "@material-ui/icons/Menu"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
})

function ListItemLink(props) {
  const { icon, primary, to } = props

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <a href={to} ref={ref} {...itemProps} />
      )),
    [to]
  )

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  )
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

function Header() {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box m={2} display="flex" justifyContent="space-between">
        <img
          className="me-logo"
          src="/img/logomarca.png"
          alt="Logomarca Grafiame"
          width="120px"
        />
        <a
          className="me-close-btn"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <CloseIcon />
        </a>
      </Box>
      <Divider />
      <List>
        <ListItemLink to="/" primary="Início" />
        <ListItemLink to="/por-que-grafiame" primary="Por que a Grafiame?" />
        <ListItemLink to="/solucoes" primary="Soluções" />
        <ListItemLink to="https://medium.com/grafiame" primary="Blog(ue)" />
        <ListItemLink to="/sobre" primary="Sobre" />
      </List>
      <Divider />
      <List>
        <Box display="flex" justifyContent="center">
          <Button
            className="me-btn me-entrar-btn"
            onClick={() => window.open("https://integradaeducativa.grafiame.com.br/", "_self")}
            variant="contained"
            color="primary"
          >
            Entrar
          </Button>
        </Box>
      </List>
    </div>
  )

  return (
    <header className="me-header">
      <Link className="logo" to="/">
        <img
          className="me-logo"
          src="/img/logomarca.png"
          alt="Logomarca Grafiame"
        />
      </Link>
      <div className="menu"></div>
      <nav className="me-nav me-screen-l">
        <Link className="link" to="/por-que-grafiame">
          Por que a Grafiame?
        </Link>
        <Link className="link" to="/solucoes">
          Soluções
        </Link>
        {/* <div className="dropdown">
          <Link className="link" to="/para-voce">
            Para você
          </Link>
          <ul className="dropdown-content">
            <li>
              <Link className="link" to="/sobre">
                Aluno(a)
              </Link>
            </li>
            <li>
              <Link className="link" to="/sobre">
                Pais ou responsáveis
              </Link>
            </li>
            <li>
              <Link className="link" to="/sobre">
                Professor(a)
              </Link>
            </li>
            <li>
              <Link className="link" to="/sobre">
                Escola
              </Link>
            </li>
            <li>
              <Link className="link" to="/sobre">
                Empresa
              </Link>
            </li>
          </ul>
        </div> */}
        <a className="link" href="https://medium.com/grafiame">
          Blog(ue)
        </a>
        <Link className="link" to="/sobre">
          Sobre
        </Link>
        {/* <div className="dropdown">
          <Link className="link" to="/sobre">
            Redação
          </Link>
          <ul className="dropdown-content">
            <li>
              <Link className="link" to="/sobre">
                Aluno(a)
              </Link>
            </li>
            <li>
              <Link className="link" to="/sobre">
                Banco de coletâneas
              </Link>
            </li>
          </ul>
        </div> */}
        <Box ml={5}>
          <Button
            className="me-btn me-entrar-btn"
            onClick={() => window.location.href = '/entrar'}
            variant="contained"
            color="primary"
          >
            Entrar
          </Button>
        </Box>
      </nav>
      <div className="me-mobile-menu">
        <button className="me-hamburger-vegano me-screen-s">
          <React.Fragment key={"right"}>
            <Button onClick={toggleDrawer("right", true)}>
              <MenuIcon />
            </Button>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
              {list("right")}
            </Drawer>
          </React.Fragment>
        </button>
      </div>
    </header>
  )
}

export default Header
